import type { ActionArgs, LoaderArgs, MetaFunction } from '@remix-run/node';
import { json, redirect } from '@remix-run/node';
import { Form, useActionData, useSearchParams } from '@remix-run/react';
import * as React from 'react';
import { Button } from '~/components/Button';

import { verifyPasswordLogin } from '~/models/user.server';
import { createUserSession, getUserId } from '~/session.server';
import { safeRedirect, validateEmail } from '~/utils';

export async function loader({ request }: LoaderArgs) {
  const userId = await getUserId(request);
  if (userId) return redirect('/');
  return json({});
}

export async function action({ request }: ActionArgs) {
  const formData = await request.formData();
  const email = formData.get('email');
  const password = formData.get('password');
  const redirectTo = safeRedirect(formData.get('redirectTo'), '/');
  const remember = formData.get('remember');

  if (!validateEmail(email)) {
    return json({ errors: { email: 'Correo no válido', password: null } }, { status: 400 });
  }

  if (typeof password !== 'string' || password.length === 0) {
    return json(
      {
        errors: { password: 'La contraseña no puede estar vacía', email: null },
      },
      { status: 400 }
    );
  }

  if (password.length < 2) {
    return json({ errors: { password: 'La contraseña es muy corta', email: null } }, { status: 400 });
  }

  const user = await verifyPasswordLogin(email, password);

  if (!user) {
    return json({ errors: { email: 'Correo o contraseña inválidos', password: null } }, { status: 400 });
  }

  return createUserSession({
    request,
    userId: user.id,
    remember: remember === 'on' ? true : false,
    redirectTo,
  });
}

export const meta: MetaFunction = () => {
  return {
    title: 'Inicio de sesión | CSG',
    description:
      'Inicia sesión en el sitio de Coordinación de Sistemas de Gestión del Instituto Tecnológico de Saltillo.',
  };
};

export default function LoginPage() {
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('redirectTo') || '/procesos';
  const actionData = useActionData<typeof action>();
  const emailRef = React.useRef<HTMLInputElement>(null);
  const passwordRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (actionData?.errors?.email) {
      emailRef.current?.focus();
    } else if (actionData?.errors?.password) {
      passwordRef.current?.focus();
    }
  }, [actionData]);

  return (
    <div className='flex flex-col justify-center mt-20'>
      <div className='mx-auto w-full max-w-md px-8 flex flex-col gap-8'>
        {/* <Form method='post' className='space-y-4' noValidate>
          <div>
            <label htmlFor='email' className='block text-sm font-medium text-gray-700'>
              Correo
            </label>
            <div className='mt-1'>
              <Input
                ref={emailRef}
                id='email'
                required
                autoFocus={true}
                name='email'
                type='email'
                autoComplete='email'
                aria-invalid={actionData?.errors?.email ? true : undefined}
                aria-describedby='email-error'
                className='w-full rounded border border-gray-500 px-2 py-1 text-lg'
              />
              {actionData?.errors?.email && (
                <div className='pt-1 text-red-700' id='email-error'>
                  {actionData.errors.email}
                </div>
              )}
            </div>
          </div>

          <div>
            <label htmlFor='password' className='block text-sm font-medium text-gray-700'>
              Contraseña
            </label>
            <div className='mt-1'>
              <Input
                id='password'
                ref={passwordRef}
                name='password'
                type='password'
                autoComplete='current-password'
                aria-invalid={actionData?.errors?.password ? true : undefined}
                aria-describedby='password-error'
                className='w-full rounded border border-gray-500 px-2 py-1 text-lg'
              />
              {actionData?.errors?.password && (
                <div className='pt-1 text-red-700' id='password-error'>
                  {actionData.errors.password}
                </div>
              )}
            </div>
          </div>

          <div className='flex items-center justify-between'>
            <div className='flex items-center'>
              <input
                id='remember'
                name='remember'
                type='checkbox'
                className='h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500'
              />
              <label htmlFor='remember' className='ml-2 block text-sm text-gray-900'>
                Recordar sesión
              </label>
            </div>
          </div>

          <input type='hidden' name='redirectTo' value={redirectTo} />
          <Button id={'sign-in-button'}>Entrar</Button>
        </Form>

        <div className='flex items-center'>
          <div className='h-px bg-slate-300 flex-1'></div>
        </div> */}

        <Form action='/auth/microsoft' method='post' className='flex flex-col space-y-5'>
          <h1>Inicia sesión</h1>
          <p>Para poder acceder a esta página debes iniciar sesión con tu correo institucional.</p>
          <Button id='microsoft-login-button' appearance={'secondary'}>
            <div className='flex gap-2 items-center'>
              <img src='/icons/ms-icon.svg' alt='Microsoft' className='w-5 h-5 mr-2' />
              <span>Entrar con Microsoft</span>
            </div>
          </Button>
        </Form>
      </div>
    </div>
  );
}
